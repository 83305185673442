import React, { useState } from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import { graphql } from 'gatsby'
import { FaEye, FaArrowLeft, FaHandPointUp, FaExpand, FaExpandAlt } from 'react-icons/fa'
import { RichText } from 'prismic-reactjs'

import { ButtonLink } from '../components/ButtonLink'
import { Image } from '../components/Image'
import { Layout } from '../components/Layout'
import { Modal } from '../components/Modal'
import { RawContentSection } from '../components/RawContentSection'
import { Products } from '../components/Products'
import { SEO } from '../components/SEO'
import { withEnlarge } from '../components/withEnlarge'

import { toUkuleleData } from '../transform'

export const pageQuery = graphql`
  query UkuleleByUID($uid: String!) {
    prismic {
      ukulele(lang: "en-nz", uid: $uid) {
        ...ukulelePage
      }
    }
  }
`

const Ukulele = withEnlarge(
  ({
    data: {
      prismic: { ukulele },
    },
    handleEnlarge,
  }) => {
    if (!ukulele) return null

    const { description, forSale, images, price, specs, title } = toUkuleleData(ukulele)

    return (
      <Layout>
        <SEO title={RichText.asText(title)} />
        <section className="bg-white px-4 py-8 md:py-12 lg:py-16">
          <div className="container max-w-3xl lg:max-w-6xl">
            <h2 className="text-5xl md:text-6xl">{RichText.asText(title)}</h2>
            <p className="text-4xl font-semibold font-heading mt-4 uppercase">
              {forSale ? `$${price}` : 'Sold'}
            </p>
            <div className="mt-4 mb-8 lg:mb-4 flex justify-between flex-wrap">
              <RawContentSection center={false} content={<RichText render={description} />} />
              <div className="lg:pl-8 w-full lg:w-1/2 flex-0 max-w-sm">
                <div className="border-4 border-gray-800 rounded p-4 w-full">
                  <h3 className="text-2xl">Specs</h3>
                  <ul className="capitalize font-heading mt-4">
                    {specs.map(({ name, value }) => (
                      <li
                        key={name}
                        className="mb-2 uppercase flex justify-between border-b border-gray-800 pb-1"
                      >
                        <span className="">{name}:</span>
                        <span className="font-bold">{value}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="mt-8">
              <div className="flex flex-wrap mt-8 md:mt-4 md:-mx-4">
                {images.map((image, i) => (
                  <div
                    key={`${image.id}-${i}`}
                    className="w-full mb-4 md:mb-0 md:p-4 md:w-1/3 cursor-pointer relative border-2 border-transparent hover:border-gray-300 rounded-md transition-all duration-150"
                    onClick={() => handleEnlarge({ image })}
                  >
                    <div className="rounded overflow-hidden">
                      <Image
                        className="z-10"
                        image={{ ...image, aspectRatio: 1 }}
                        alt={RichText.asText(title)}
                        title={RichText.asText(title)}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="mt-4">
              <ButtonLink className="inline-flex items-center" to="/ukuleles">
                <FaArrowLeft /> <span className="ml-2">All ukuleles</span>
              </ButtonLink>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
)

export default Ukulele
